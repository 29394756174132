import profile from '../resources/media/Profile.jpg';
import about from '../resources/media/About.jpg';

import neilBot from '../resources/projects/NeilBot.png';
import valenciaWeb from '../resources/projects/ValenciaTechClub.png';
import engage from '../resources/projects/UnofficialEngageApi.png';
import portfolioWebsite from '../resources/projects/Portfolio.png';
import pills from '../resources/projects/CrazyPills.jpg';

const constants = {
  profile: profile,
  heroText: "Starting in high school, I've been on a nonstop journey toward computer mastery. From basic web development to full stack, software development, and even server administration, I have continued to acquire skill after skill, whether for hobbies, small communities, or work. I am confident that whatever comes my way, I will always be on a path of knowledge and growth.",
  aboutPic: about,
  aboutText: [
    "I am a passionate developer who takes joy in creating solutions to various software problems. With a strong grasp of many programming languages and in-depth knowledge of Linux operating systems and the cloud, I specialize not only in creating real-world applications but also in deployment, scalability, and maintenance. My experience has been building since I was a high schooler in 2018, where I had the opportunity to work on Ubuntu servers, cloud computing, a variety of web stacks, and more.",
    "I am highly motivated when working with others and naturally fill my role, whether as someone eager to learn and improve or as a leader. If I'm not programming, I'm teaching, and if I'm not teaching, I'm learning.",
    "Web engineering may be my career, but technology is my hobby."
  ],
  experience: [
    {
      title: "Computer Programming Tutor",
      place: "Valencia College",
      time: "August 2023 - Present",
      skills: ["C", "Java", "C++", "JavaScript", "Microsoft Office Suite", "Teaching"],
      description: "As a tutor, I assist students with various programming courses, leveraging my experience to help with both familiar and unfamiliar subjects. Common areas of assistance include introductory programming, C, Java, advanced courses, C#, scripting languages, computer networking, and Microsoft Office applications."
    },
    {
      title: "Valencia Tech Club President",
      place: "Valencia College",
      time: "January 2023 - Present",
      skills: ["Leadership", "Web Development", "Public Speaking", "Organizing"],
      description: "As president of the Tech Club, I organize technology-related activities such as workshops, tech talks, showcases, and competitions. My role demands strong leadership, organizational skills, and technical proficiency to provide valuable experiences for club members."
    },
    {
      title: "System Administrator",
      place: "King's Playground",
      time: "July 2019 - January 2023",
      skills: ["ASP.NET", "MySQL", "Ubuntu Server", "Nginx", "C# .NET", "SSH", "Shell/Bash"],
      description: "At King's Playground, an online multiplayer gaming community, I developed and integrated custom software solutions to enhance game moderation, staff management, and gameplay. Starting as a community leader, I bridged the gap between development and leadership, contributing to the community's success."
    },
    {
      title: "Web Developer",
      place: "Freelance",
      time: "September 2020 - May 2021",
      skills: ["ReactJS", "ReactBootstrap", "JavaScript", "NodeJS", "Express", "Axios", "Firebase"],
      description: "I began my web development journey in a small team, learning and collaborating on projects. We created websites for family and online groups using ReactJS and Bootstrap, managing projects through GitHub and integrating databases with Firebase."
    },
  ],
  projects: [
    {
      title: "Lesser Server Control",
      technologies: ["NodeJS", "DiscordJS", "Axios"],
      link: "https://github.com/Neil925/LesserServerControl",
      img: neilBot,
      description: "A DiscordJS bot that integrates with the Pterodactyl Panel API to allow limited server management access from a Discord channel using message commands."
    },
    {
      title: "Valencia Tech Club Website",
      technologies: ["ReactJS", "SCSS", "TypeScript"],
      link: "https://vtech.neilelkadi.com/",
      img: valenciaWeb,
      description: "A website made by and for Valencia Tech Club. The site presents general information about the club, its notable members/leaders, and resources on how to join."
    },
    {
      title: "Unofficial Engage API",
      technologies: ["NodeJS", "TypeScript", "ExpressJS", "Axios", "SQLite"],
      link: "https://github.com/Neil925/unofficial-engage-api",
      img: engage,
      description: "The Unofficial Engage API provides a way to pull event data from the Engage website without having to use Engage's developer API. It accomplishes this by scraping publicly available data from the Engage website. All information is then stored in a SQLite database."
    },
    {
      title: "Portfolio Website",
      technologies: ["ReactJS", "TypeScript", "TailWind"],
      link: "https://github.com/Neil925/neil-elkadi",
      img: portfolioWebsite,
      description: "The very site you see before you. Learn more about me and my work through this website and the resources I have linked!"
    },
    {
      title: "Crazy Pills",
      technologies: ["C#", ".NET", "Northwood API"],
      link: "https://github.com/Neil925/CrazyPills",
      img: pills,
      description: "An SCP:SL plugin that allows for randomized and fun events to occur upon consumption of the in-game item 'Pain Killers.'"
    },
  ]
};

export default constants;
